<template>
  <div>
    <pageTitle title="业务进度跟踪" style="background: #fff"></pageTitle>
    <div class="content">
      <div class="list">
        <div class="label">
          <div class="labelTitle">状态</div>
          <div class="marginT38">资料提交时限</div>
        </div>
        <div v-for="(item, index) in dataList" :key="index" class="labelItem">
          <div :class="item.status_update_time ? 'colorBlue' : ''">{{ item.name }}</div>
          <div class="marginT10" :class="item.status_update_time ? 'colorBlue' : ''">{{ item.node_status }}</div>
          <div class="marginT10 status_update_time">
            <span v-if="item.status_update_time">
                {{ item.status_update_time | secondFormat('LL') }}
            </span>
          </div>
          <div class="marginT10" :class="isEdit ? 'colorRed' : 'color999'">
            {{ item.latest_days | secondFormat('LL') }}
          </div>
        </div>
      </div>
      <tabs class="tabs" @upEtd="getEtd"></tabs>
    </div>
  </div>
</template>

<script>
import pageTitle from "~bac/components/common/pageTitle";
import tabs from "~bac/components/dealManagement/myEntrust/detailsOrdered/tabs"

export default {
  name: "detailsOrdered",
  components: { pageTitle, tabs },
  data() {
    return {
      dataList: [
        {
          name: '订舱',
          status: '已订舱',
          date: '4-10'
        },
      ],
      isEdit: false,
      dataRow: {
        id: '',
        status_update_time: ''
      },
      editDialog: false
    }
  },
  mounted() {
    this.getDataList()
  },
  methods: {
    async getDataList() {
      let params = {
        order_id: this.$route.query.order_id
      }
      let res = await this.$store.dispatch("baseConsole/getBookingBusinessOrderNodeinfo", params);
      if (res.success) {
        this.dataList = res.data
      }
    },
    getEtd(etd) {
      // 当前时间
      let nowData = this.$moment().unix();
      // 开航时间大于时间，能修改
      if (etd > nowData) {
        this.isEdit = true
      } else {
        this.isEdit = false
      }
    },
  }
}
</script>

<style scoped lang="less">
.content {
  border-top: 1px solid #cccccc;
  .list {
    padding: 12px;
    display: flex;
    font-size: 16px;
    border-bottom: 1px solid #cccccc;
    background: #ffffff;
  }
  .label {
    padding-right: 40px;
    text-align: right;
    .labelTitle {
      line-height: 50px;
    }
  }
  .labelItem {
    padding: 0 20px;
    border-left: 1px dashed #cccccc;
  }
}
.tabs {
  margin-top: 10px;
  background: #FFFFFF;
  padding: 12px;
}

.marginT10 {
  margin-top: 10px;
}

.marginT38 {
  margin-top: 38px;
}

.colorGreen {
  color: rgb(82, 196, 26)
}

.color999 {
  color: #999999;
}
.colorGreen {
  color: rgb(82, 196, 26)
}
.color999 {
  color: #999999;
}
.status_update_time {
  font-size: 14px;
  color: #cccccc;
  height: 18px;
}

.colorBlue {
  color: #20a0ff;
}

.colorRed {
  color: red;
}

.color999 {
  color: #999999;
}
.btns {
  margin-top: 120px;
  text-align: right;
}
.point {
  cursor: pointer;
}
</style>