<template>
  <div>
    <el-tabs v-model="activeName" @tab-click="handleClick" class="tabs">
      <el-tab-pane label="基本" name="first"></el-tab-pane>
      <el-tab-pane label="订舱" name="second"></el-tab-pane>
      <el-tab-pane label="费用" name="third"></el-tab-pane>
      <el-tab-pane label="文件" name="fourth"></el-tab-pane>
    </el-tabs>
    <div class="content" v-loading="loading">
      <div v-show="activeName === 'first'" class="tabsItem">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>委托信息</span>
          </div>
          <div class="item">
            <div class="label">业务编号</div>
            <div class="value">{{ firstData.job_no | textFormat }}</div>
          </div>
          <div class="item">
            <div class="label">委托单位</div>
            <div class="value">{{ firstData.entrust_company_en | priorFormat(firstData.entrust_company_zh, LOCALE)  }}</div>
          </div>
          <div class="item">
            <div class="label">整箱类型</div>
            <div class="value">
              {{ firstData.spell_type | spellTypeFormat  }}</div>
          </div>
          <div class="item">
            <div class="label">货物类型</div>
            <div class="value">
              {{ firstData.cargo_type | cargoTypeFormat  }}</div>
          </div>
          <div class="item">
            <div class="label">开航日期</div>
            <div class="value">
              {{ firstData.etd | secondFormat('LL')  }}</div>
          </div>
          <div class="item">
            <div class="label">起运港(POL)</div>
            <div class="value">
              [{{ firstData.from_port_code | textFormat  }}]
              {{ firstData.from_port_name_en | textFormat  }}
            </div>
          </div>
          <div class="item">
            <div class="label">卸货港(POD)</div>
            <div class="value">
              [{{ firstData.unload_port_code | textFormat  }}]
              {{ firstData.unload_port_name_en | textFormat  }}
            </div>
          </div>
          <div class="item">
            <div class="label">交货地</div>
            <div class="value">
              {{ firstData.dest_port | textFormat  }}
            </div>
          </div>
          <div class="item">
            <div class="label">航线</div>
            <div class="value">
              <div class="value">{{ firstData.shipping_line_name_en | priorFormat(firstData.shipping_line_name_cn, LOCALE)  }}</div>
            </div>
          </div>
          <div class="item">
            <div class="label">船公司</div>
            <div class="value">
              <div class="value">{{ firstData.shipping_company_name_en | priorFormat(firstData.shipping_company_name_zh, LOCALE)  }}</div>
            </div>
          </div>
        </el-card>
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>附加信息</span>
          </div>
          <div class="item">
            <div class="label">进仓编号</div>
            <div class="value">{{ firstData.warehouse_no | textFormat }}</div>
          </div>
          <div class="item">
            <div class="label">贸易方式</div>
            <div class="value">{{ firstData.trade_mode | textFormat }}</div>
          </div>
          <div class="item">
            <div class="label">运输条款</div>
            <div class="value">{{ firstData.shipping_terms_code | textFormat }}</div>
          </div>
          <div class="item">
            <div class="label">运费条款</div>
            <div class="value">{{ firstData.fee_terms_code | textFormat }}</div>
          </div>
          <div class="item">
            <div class="label">贸易条款</div>
            <div class="value">{{ firstData.trade_terms | textFormat }}</div>
          </div>
          <div class="item">
            <div class="label">销售</div>
            <div class="value">{{ firstData.sales_name_en | priorFormat(firstData.sales_name_zh, 'zh')  }}</div>
          </div>
          <div class="item">
            <div class="label">客服</div>
            <div class="value">{{ firstData.customer_service_name_en | priorFormat(firstData.customer_service_name_zh, 'zh')  }}</div>
          </div>
        </el-card>
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>箱货信息</span>
          </div>
          <div class="item">
            <div class="label">件数</div>
            <div class="value">{{ firstData.quantity | textFormat }}PCS</div>
          </div>
          <div class="item">
            <div class="label">毛重</div>
            <div class="value">{{ firstData.gross_weight | textFormat }}KGS</div>
          </div>
          <div class="item">
            <div class="label">体积</div>
            <div class="value">{{ firstData.volume | textFormat }}CBM</div>
          </div>
          <div class="item">
            <div class="label">包装</div>
            <div class="value">{{ firstData.packing_type_code | textFormat }}</div>
          </div>
          <div class="item">
            <div class="label">小件数</div>
            <div class="value">{{ firstData.inside_quantity | textFormat }}</div>
          </div>
          <div class="item">
            <div class="label">英文品名</div>
            <div class="value" :title="firstData.cargo_name_en | textFormat">{{ firstData.cargo_name_en | textFormat }}</div>
          </div>
          <div class="item">
            <div class="label">唛头</div>
            <div class="value" :title="firstData.marks | textFormat">{{ firstData.marks | textFormat }}</div>
          </div>
          <div class="item">
            <div class="label">HS编号</div>
            <div class="value">{{ firstData.hs_code | textFormat }}</div>
          </div>
          <div class="item">
            <div class="label">中文品名</div>
            <div class="value" :title="firstData.cargo_name_zh | textFormat">{{ firstData.cargo_name_zh | textFormat }}</div>
          </div>
        </el-card>
      </div>
      <div v-show="activeName === 'second'" class="tabsItem">
        <el-card class="box-card width39">
          <div slot="header" class="clearfix">
            <span>订舱信息</span>
          </div>
          <div class="item">
            <div class="label">船名</div>
            <div class="value">
              <div class="value">{{ firstData.vessel | textFormat  }}</div>
            </div>
          </div>
          <div class="item">
            <div class="label">航次</div>
            <div class="value">
              <div class="value">{{ firstData.voyage_flight | textFormat  }}</div>
            </div>
          </div>
          <div class="item">
            <div class="label">S/O NO.</div>
            <div class="value">
              <div class="value">{{ firstData.stowage_no | textFormat  }}</div>
            </div>
          </div>
          <div class="item">
            <div class="label">MB/L NO</div>
            <div class="value">
              <div class="value">{{ firstData.mbl | textFormat  }}</div>
            </div>
          </div>
          <div class="item">
            <div class="label">订舱编号</div>
            <div class="value">
              <div class="value">{{ firstData.booking_no | textFormat  }}</div>
            </div>
          </div>
          <div class="item">
            <div class="label">截单日期</div>
            <div class="value">
              <div class="value">{{ firstData.dod | secondFormat('LL')  }}</div>
            </div>
          </div>
          <div class="item">
            <div class="label">截关日期</div>
            <div class="value">
              <div class="value">{{ firstData.cod | secondFormat('LL')  }}</div>
            </div>
          </div>
        </el-card>
        <el-card class="box-card width60">
          <div slot="header" class="clearfix">
            <span>订舱信息</span>
          </div>
          <div>
            发货人
            <div class="item2">
              <div v-for="(item, index) in firstData.consignee" :key="index">
                {{ item }}
              </div>
            </div>
          </div>
          <div>
            收货人
            <div class="item2">
              <div v-for="(item, index) in firstData.shipper" :key="index">
                {{ item }}
              </div>
            </div>
          </div>
          <div>
            通知人
            <div class="item2">
              <div v-for="(item, index) in firstData.notify_party" :key="index">
                {{ item }}
              </div>
            </div>
          </div>
        </el-card>
      </div>
      <div v-show="activeName === 'third'">
        <el-card class="box-card width100">
          <div slot="header" class="clearfix">
            <span>应付</span>
          </div>
          <el-table
              :data="thirdData.payable_list"
              border
              style="width: 100%">
            <el-table-column prop="currency_code" label="币种" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="currency_code" label="费用名称" :show-overflow-tooltip="true">
              <template slot-scope="scope">
                {{ scope.row.charge_name_en | priorFormat(scope.row.charge_name_zh, LOCALE)  }}
              </template>
            </el-table-column>
            <el-table-column prop="measure" label="单位" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="quantity" label="数量" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="unit_rate" label="单价" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="amount" label="含税价" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="no_val_amount" label="不含税价" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="val_rate" label="税率" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="val_amount" label="税额" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="remarks" label="备注" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="billing_no" label="账单号" :show-overflow-tooltip="true"></el-table-column>
          </el-table>
        </el-card>
        <el-card class="box-card width100" style="margin-top: 20px">
          <div slot="header" class="clearfix">
            <span>应收</span>
          </div>
          <el-table
              :data="thirdData.receivable_list"
              border
              style="width: 100%">
            <el-table-column prop="currency_code" label="币种" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="currency_code" label="费用名称" :show-overflow-tooltip="true">
              <template slot-scope="scope">
                {{ scope.row.charge_name_en | priorFormat(scope.row.charge_name_zh, LOCALE)  }}
              </template>
            </el-table-column>
            <el-table-column prop="measure" label="单位" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="quantity" label="数量" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="unit_rate" label="单价" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="amount" label="含税价" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="no_val_amount" label="不含税价" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="val_rate" label="税率" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="val_amount" label="税额" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="remarks" label="备注" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="billing_no" label="账单号" :show-overflow-tooltip="true"></el-table-column>
          </el-table>
        </el-card>
      </div>
      <div v-show="activeName === 'fourth'">
        <el-button size="small" type="primary" icon="el-icon-plus" @click="handleAddDataBank()">上传</el-button>
        <div class="line"></div>
        <el-table
            :data="fourthData"
            style="width: 100%;"
        >
          <el-table-column label="文件名称" prop="name">
            <div slot-scope="scope">
              <el-button type="text" @click="handle_open(scope.row.url)">{{ scope.row.name }}</el-button>
            </div>
          </el-table-column>
          <el-table-column label="文件类型" prop="name">
            <div slot-scope="scope">
              {{ scope.row.file_type | file_type_filter }}
            </div>
          </el-table-column>
          <el-table-column label="文件格式" prop="format">
            <div slot-scope="scope">
              {{ scope.row.format | textFormat }}
            </div>
          </el-table-column>
          <el-table-column label="文件大小" prop="size">
            <div slot-scope="scope">
              <div v-if="scope.row.size"> {{ scope.row.size | textFormat }}KB</div>
              <div v-else>-</div>
            </div>
          </el-table-column>
          <el-table-column label="创建日期" prop="created_time">
            <div slot-scope="scope">
              {{ scope.row.created_time | secondFormat }}
            </div>
          </el-table-column>
          <el-table-column label="备注" prop="remarks" :show-overflow-tooltip="true">
            <div slot-scope="scope">
              {{ scope.row.remarks | textFormat }}
            </div>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <el-dialog title="文件上传" :visible.sync="uploadingFileDialog" width="700px">
      <uploadDialog @handleClose="handleCloseDialog" v-if="uploadingFileDialog"></uploadDialog>
    </el-dialog>
  </div>
</template>

<script>
import uploadDialog from "~bac/components/dealManagement/myEntrust/detailsOrdered/uploadingFileDialog"
export default {
  name: "tabs",
  components: { uploadDialog },
  data() {
    return {
      activeName: 'first',
      firstData: {},
      thirdData: {
        receivable_list: [],
        payable_list: [],
      },
      fourthData: [],
      loading: false,
      uploadingFileDialog: false
    }
  },
  filters: {
    spellTypeFormat(val) {
      let obj = {
        1: '整箱',
        2: '拼箱',
        3: '委托拼箱',
        4: 'Buyer Consol',
        5: '散杂货',
        6: '特种箱',
      }
      return obj[val] || '-'
    },
    cargoTypeFormat(val) {
      let obj = {
        1: '危险品',
        2: '普货',
        3: '挂衣箱',
        4: '大件货',
        5: '温控货',
      }
      return obj[val] || '-'
    },
    file_type_filter(val) {
      let str = '-'
      if (val == 1) {
        str = '海运委托书'
      }
      if (val == 2) {
        str = 'MSDS'
      }
      if (val == 3) {
        str = '危包证'
      }
      if (val == 4) {
        str = '商检报告'
      }
      if (val == 5) {
        str = '其他'
      }
      return str
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    handleClick(tab, event) {
      this.uploadingFileDialog = false
     this.getList()
    },
    getList() {
      this.loading = true
      if (this.activeName === 'first' || this.activeName === 'second') {
        this.getFirstData()
      }
      if (this.activeName === 'third') {
        this.getThirdInfo()
      }
      if (this.activeName === 'fourth') {
        this.getFourthInfo()
      }
    },
    async getFirstData() {
      let params = {
        entrust_id: this.$route.query.id
      }
      let res = await this.$store.dispatch("baseConsole/getBookingbusinessOrderBasicInfo", params);
      this.loading = false
      if (res.success) {
        this.firstData = res.data
        this.$emit('upEtd', res.data.etd)
      }
    },
    async getThirdInfo() {
      let params = {
        entrust_id: this.$route.query.id
      }
      let res = await this.$store.dispatch("baseConsole/getBookingbusinessOrderInfo", params);
      this.loading = false
      if (res.success) {
        this.thirdData = res.data
      }
    },
    async getFourthInfo() {
      let params = {
        entrust_id: this.$route.query.id
      }
      let res = await this.$store.dispatch("baseConsole/getBookingbusinessOrderFilesInfo", params);
      this.loading = false
      if (res.success) {
        this.fourthData = res.data
        console.log(this.fourthData, '===');
      }
    },
    // 上传资料
    handleAddDataBank() {
      this.uploadingFileDialog = true
    },
    //直播链接跳转
    handle_open(url) {
      window.open(url, "", "", false)
    },
    handleCloseDialog() {
      this.uploadingFileDialog = false
      this.getList()
    }
  }
}
</script>

<style scoped lang="less">
.tabs {
  /deep/ .el-tabs__nav-wrap {
    .el-tabs__item {
      padding: 0 12px;
      height: 48px;
      line-height: 48px;
      font-size: 16px;

      &.is-active {
        font-weight: 700;
      }
    }

    &::after {
      height: 1px;
      background-color: #e4e7ed;
      z-index: 1;
    }
  }
}
.content {
  min-height: 500px;
}
.tabsItem {
  display: flex;
  justify-content: space-between;

  .box-card {
    width: 33%;
    .item {
      width: 100%;
      display: flex;
      margin-bottom: 30px;
      .label {
        width: 100px;
        font-weight: 500;
      }
      .value {
        flex: 1;
      }
    }
    .item2 {
      border: 1px solid #cccccc;
      margin: 20px 0;
      div {
        padding: 4px 10px;
        border-bottom: 1px solid #cccccc;
      }
      div:last-child {
        border-bottom: none;
      }
    }
  }
  .width39 {
    width: 39%;
  }
  .width60 {
    width: 60%;
  }
  .width100 {
    width: 100%;
  }
}
.line {
  width: 100%;
  height: 1px;
  background: #C0C4CC;
  margin: 10px 0;
}
</style>